import PropTypes from "prop-types";
import React from "react";

const SEO = ({ title }) => {
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title} || Expert Repairs, Exceptional Service</title>
      <meta name="robots" content="noindex, follow" />
      <meta
        name="description"
        content="At ASKTECK Solutions, we specialize in delivering expert laptop and printer repairs with unparalleled convenience and quality. Our skilled technicians handle every device with precision, ensuring optimal performance and reliability. With no upfront consultation fees and a generous 200-day warranty on all repairs, we’re dedicated to providing exceptional service that meets your needs and exceeds your expectations."
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
    </>
  );
};
SEO.propTypes = {
  title: PropTypes.string,
};

export default SEO;
