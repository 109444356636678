import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    {
        id: "1",
        title: "Schedule Your Service",
        description: "Book an appointment online or call us for a quick consultation, with no upfront fees required.",
    },
    {
        id: "2",
        title: "Doorstep Pickup",
        description: "Our team will pick up your device from your location at a time convenient for you.",
    },
    {
        id: "3",
        title: "Diagnosis and Repair",
        description: "Our experts perform a thorough diagnosis and repair, including chip-level service if needed.",
    },
    {
        id: "4",
        title: "Delivery and Support",
        description: "We deliver your repaired device back to you, along with a 200-day warranty for peace of mind.",
    }
]

const TimelineTwo = ({classVar}) => {
    return (
        <div className="timeline-style-two bg-color-blackest">
            <div className="row row--0">
                {/* Start Single Progress */}
                {TimelineData.map((data , index) =>(
                    <div className={`col-lg-3 col-md-3 rn-timeline-single ${classVar}`} key={index}>
                        <div className="rn-timeline">
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <h6 className="title">{data.title}</h6>
                            </ScrollAnimation>
                            <div className="progress-line">
                                <div className="line-inner"></div>
                            </div>
                            <div className="progress-dot">
                                <div className="dot-level">
                                    <div className="dot-inner"></div>
                                </div>
                            </div>
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <p className="description">{data.description}</p>
                            </ScrollAnimation>
                        </div>
                    </div>
                ))}
                {/* End Single Progress */}
            </div>
        </div>
    )
}
export default TimelineTwo;